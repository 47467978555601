import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './footer.module.css';
import CtaLink from '../Global/CtaLink';
import {
    BrandColor,
    KontentRichText,
    KontentIcon,
    KontentStringValue,
    KontentMedia,
    KontentRadioMultiChoice,
    CtaTsType,
} from '../../../types/KontentProps';
import {
    getColor,
    getMultiChoice,
    getIcon,
    getMediaData,
} from '../Global/DataUtils';
import RichText from '../Global/RichText';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import BlogSubscriptionForm from '../Global/BlogSubscriptionForm';
import { CountrySelectProps } from '../CountrySelectOverlay';
import CountrySelectOverlay from '../CountrySelectOverlay';

interface FooterRichTextConfiguration {
    elements: { content: KontentRichText };
    system: {
        type: string;
    };
}

interface SocialIconConfiguration {
    system: {
        type: string;
    };
    elements: {
        social_icons: {
            value: {
                elements: {
                    socialCta_location: {
                        value: string;
                    };
                    icon: KontentIcon;
                };
            }[];
        };
    };
}

interface MobileAppsConfiguration {
    elements: {
        cta_location_apple: KontentStringValue;
        cta_location_android: KontentStringValue;
        display_headline: KontentStringValue;
        image_apple: KontentMedia;
        image_android: KontentMedia;
    };
    system: {
        type: string;
    };
}

interface FooterContent {
    value: FooterContentConfigurations[];
}

type FooterContentConfigurations =
    | CtaTsType
    | FooterRichTextConfiguration
    | SocialIconConfiguration
    | MobileAppsConfiguration;

type FormDisplayOptions = 'show_form' | 'hide_form';

export interface FooterProps {
    font_color: BrandColor;
    background_color: BrandColor;
    copyright_language: KontentRichText;
    display: KontentRadioMultiChoice<FormDisplayOptions>;
    category_1_headline: KontentStringValue;
    category_1_content: FooterContent;
    category_2_headline: KontentStringValue;
    category_2_content: FooterContent;
    category_3_headline: KontentStringValue;
    category_3_content: FooterContent;
    country_select_overlay_configuration: {
        value: { elements: CountrySelectProps }[];
    };
    form: KontentStringValue;
    submit_cta_label: KontentStringValue;
    interested_in_field: KontentStringValue;
    form_headline: KontentStringValue;
    email_address_field: KontentStringValue;
    name_field: KontentStringValue;
    form_confirmation_message: KontentStringValue;
    blog_topic: {
        value: [
            {
                name: string;
            }
        ];
    };
    interest_hint_text: {
        value: string;
    };
    legal_requirements: KontentRichText;
    privacy_choices_icon: {
        value: {
            url: string;
            name: string;
        }[];
    };
    privacy_choices_icon_hyperlink: {
        value: string;
    };
}

const Footer: React.FC<FooterProps> = ({ ...props }) => {
    const privacyChoiceIcon = props.privacy_choices_icon.value[0];
    const privacyIconCta = `<a href=/${props.privacy_choices_icon_hyperlink.value} className = ${styles.legalreq}><img src=${privacyChoiceIcon?.url} alt=${privacyChoiceIcon?.name} /></a>`;
    const modifiedLegalReq = { ...props.legal_requirements };
    modifiedLegalReq.value =
        modifiedLegalReq.value.slice(0, -4) +
        privacyIconCta +
        modifiedLegalReq.value.slice(-4);
    const showForm = getMultiChoice(props.display) === 'show_form';

    const fontColor = getColor(props.font_color) || undefined;

    const backgroundColor = getColor(props.background_color) || undefined;
    const authorStyles = {
        color: fontColor,
        backgroundColor: backgroundColor,
        borderTopColor: backgroundColor,
    };

    const renderFooterRte = (
        content: FooterRichTextConfiguration,
        idx: number
    ) => {
        const panelName = { value: 'footer' };
        return (
            <div className={styles.richText} key={'footerRte-' + idx}>
                {RichText(content.elements?.content, true, panelName)}
            </div>
        );
    };

    const renderFooterCta = (content: CtaTsType, idx: number) => {
        const windowAction =
            getMultiChoice(content.elements.cta_window_action) === 'same_window'
                ? '_self'
                : '_new';
        return (
            <div
                className={styles.linkButtonContainer}
                key={'footerCta-' + idx}
            >
                <CtaLink
                    id={
                        content.elements.cta_label.value?.replace(/ /g, '-')
                            ? `footer__${content.elements.cta_label.value?.replace(
                                  / /g,
                                  '-'
                              )}`
                            : undefined
                    }
                    href={BuildLinkHref(
                        content.elements.cta_location,
                        content.elements.cta_location_anchor_point
                    )}
                    target={windowAction}
                >
                    {content.elements.cta_label.value}
                </CtaLink>
            </div>
        );
    };

    const renderFooterSocialIcons = (
        content: SocialIconConfiguration,
        idx: number
    ) => {
        const socialIcons = content.elements?.social_icons.value;
        return (
            <div
                className={styles.socialIcons}
                key={'footerSocialIcons-' + idx}
            >
                {socialIcons.map((socialIcon, idx) => {
                    const iconData = getIcon(socialIcon.elements?.icon);
                    if (!iconData.url) return null;
                    const iconStyles = {
                        backgroundImage: `url(${iconData.url})`,
                        maskImage: `url(${iconData.url})`,
                        WebkitMaskImage: `url(${iconData.url})`,
                        maskRepeat: 'no-repeat',
                        maskPosition: 'center',
                    };

                    return (
                        <div
                            className={styles.linkIcon}
                            key={'socialIcon-' + idx}
                        >
                            <a
                                id={`footer__${iconData.description}`}
                                href={
                                    socialIcon.elements?.socialCta_location
                                        ?.value
                                }
                                target="_new"
                                className={styles.icon}
                                style={iconStyles}
                            >
                                <span className="sr-only">
                                    {iconData.description || iconData.name}
                                </span>
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderMobileApps = (
        content: MobileAppsConfiguration,
        idx: number
    ) => {
        const appleImage = getMediaData(content.elements.image_apple);
        const androidImage = getMediaData(content.elements.image_android);

        return (
            <div className={styles.mobileApps} key={'mobileApps-' + idx}>
                <p className={styles.categoryHeadline}>
                    {content.elements?.display_headline.value}
                </p>
                <div className={styles.mobileAppsIcons}>
                    {appleImage?.url && (
                        <a
                            id={`footer__${
                                appleImage?.name
                                    ?.replace(/ /g, '-')
                                    .split('.')[0]
                            }`}
                            href={content.elements.cta_location_apple.value}
                            target="_new"
                        >
                            <img
                                className={styles.mobileStoreBtn}
                                src={appleImage?.url}
                                alt={appleImage?.description}
                            />
                        </a>
                    )}
                    {androidImage?.url && (
                        <a
                            id={`footer__${
                                androidImage?.name
                                    ?.replace(/ /g, '-')
                                    .split('.')[0]
                            }`}
                            href={content.elements.cta_location_android.value}
                            target="_new"
                        >
                            <img
                                className={styles.mobileStoreBtn}
                                src={androidImage?.url}
                                alt={androidImage?.description}
                            />
                        </a>
                    )}
                </div>
            </div>
        );
    };

    const renderContentItem = (
        content: FooterContentConfigurations,
        idx: number
    ) => {
        switch (content.system?.type) {
            case 'component___rte':
                return renderFooterRte(
                    content as FooterRichTextConfiguration,
                    idx
                );
            case 'content_item___cta':
                return renderFooterCta(content as CtaTsType, idx);
            case 'content_item___social_icons':
                return renderFooterSocialIcons(
                    content as SocialIconConfiguration,
                    idx
                );
            case 'content_item___mobile_app_links':
                return renderMobileApps(
                    content as MobileAppsConfiguration,
                    idx
                );
            default:
                return null;
        }
    };
    const renderNavSection = (
        headline: KontentStringValue,
        content: FooterContent
    ) => {
        return (
            <div className={styles.navSection}>
                <p className={styles.categoryHeadline}>{headline.value}</p>
                {content.value.map((content, idx) => {
                    return renderContentItem(content, idx);
                })}
            </div>
        );
    };

    return (
        <footer className={styles.wrapper} style={authorStyles}>
            <div className={styles.navContent} style={authorStyles}>
                {renderNavSection(
                    props.category_1_headline,
                    props.category_1_content
                )}

                {renderNavSection(
                    props.category_2_headline,
                    props.category_2_content
                )}

                {renderNavSection(
                    props.category_3_headline,
                    props.category_3_content
                )}
            </div>
            {showForm && (
                <div className={styles.connectForm}>
                    <BlogSubscriptionForm
                        headline={props.form_headline.value}
                        cta={props.submit_cta_label?.value}
                        emailAddressLabel={props.email_address_field?.value}
                        form_confirmation_message={
                            props.form_confirmation_message?.value
                        }
                        nameLabel={props.name_field.value}
                        interestedFieldLabel={props.interested_in_field.value}
                        blogTopics={props.blog_topic.value}
                        topics={[]}
                        interest_hint_text={props.interest_hint_text?.value}
                    />
                </div>
            )}
            <div className={styles.copyright}>
                {privacyChoiceIcon?.url ? (
                    <div className={styles.modifiedreq}>
                        {RichText(modifiedLegalReq)}
                    </div>
                ) : (
                    RichText(props.legal_requirements)
                )}
                {RichText(props.copyright_language)}
            </div>
            {props.country_select_overlay_configuration?.value[0] && (
                <div className={styles.coutryStyle}>
                    <CountrySelectOverlay
                        {...props.country_select_overlay_configuration.value[0]
                            .elements}
                    />
                </div>
            )}
        </footer>
    );
};

export default Footer;

export const fragmentFooter = graphql`
    fragment Footer on kontent_item_component___footer {
        elements {
            blog_topic {
                value {
                    codename
                    name
                }
            }
            form {
                value
            }
            email_address_field {
                value
            }
            name_field {
                value
            }
            form_confirmation_message {
                value
            }
            form_headline {
                value
            }
            font_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            background_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            copyright_language {
                ...kontentRichText
            }
            display {
                value {
                    codename
                }
            }
            interested_in_field {
                value
            }
            submit_cta_label {
                value
            }
            category_1_headline {
                value
            }
            category_1_content {
                ...kontentFooterContent
            }
            category_2_headline {
                value
            }
            category_2_content {
                ...kontentFooterContent
            }
            category_3_headline {
                value
            }
            category_3_content {
                ...kontentFooterContent
            }
            country_select_overlay_configuration {
                ...countrySelectOverlay
            }
            interest_hint_text {
                value
            }
            legal_requirements {
                ...kontentRichText
            }
            privacy_choices_icon {
                value {
                    url
                }
            }
            privacy_choices_icon_hyperlink {
                value
            }
        }
    }
`;

export const fragmentFooterContent = graphql`
    fragment kontentFooterContent on kontent_item_modular_content_element_value {
        value {
            ... on kontent_item_component___rte {
                system {
                    type
                }
                elements {
                    content {
                        ...kontentRichText
                    }
                }
            }
            ... on kontent_item_content_item___cta {
                system {
                    type
                }
                ...kontentCta
            }
            ... on kontent_item_content_item___mobile_app_links {
                system {
                    type
                }
                elements {
                    cta_location_apple {
                        value
                    }
                    cta_location_android {
                        value
                    }
                    display_headline {
                        value
                    }
                    image_apple {
                        value {
                            ... on kontent_item_media___image {
                                id
                                elements {
                                    file {
                                        value {
                                            description
                                            name
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    image_android {
                        value {
                            ... on kontent_item_media___image {
                                id
                                elements {
                                    file {
                                        value {
                                            description
                                            name
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ... on kontent_item_content_item___social_icons {
                id
                system {
                    type
                }
                elements {
                    social_icons {
                        value {
                            ... on kontent_item_content_item___social_icon {
                                elements {
                                    socialCta_location: cta_location {
                                        value
                                    }
                                    icon {
                                        value {
                                            ... on kontent_item_media___icon {
                                                id
                                                elements {
                                                    ...kontentIcon
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
