import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
//@ts-ignore
import * as styles from './blog-subscription-form.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { components } from 'react-select';
//@ts-ignore
import Cookies from 'js-cookie';

const ValueContainer = ({ children, ...props }: any) => {
    const {
        getValue,
        hasValue,
        isMulti,
        selectProps: { inputValue },
    } = props;
    if (!hasValue || !isMulti) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }
    return (
        <components.ValueContainer {...props}>
            {!inputValue && (
                <components.Placeholder {...props}>{`${
                    getValue().length
                } interest(s)`}</components.Placeholder>
            )}
            {children[1]}
        </components.ValueContainer>
    );
};

type blogTopic = {
    name: string;
};
type Topic = {
    name: string;
    email: string;
    topics: string;
};

interface Props {
    headline: string;
    cta: string;
    topics: Topic[];
    blogTopics: blogTopic[];
    interestedFieldLabel: string;
    emailAddressLabel: string;
    nameLabel: string;
    form_confirmation_message: string;
    interest_hint_text: string;
}

interface DataFields {
    fieldName: string;
    fieldValue: string;
    isHidden: string;
    fieldLabel: string;
    isRequired: string;
}

const BlogSubscriptionForm: React.FC<Props> = ({
    headline,
    cta,
    interestedFieldLabel,
    emailAddressLabel,
    blogTopics,
    nameLabel,
    form_confirmation_message,
    interest_hint_text,
}) => {
    const { register } = useForm<Topic>();
    const [NameValue, setNameValue] = useState('');
    const [ipDetails, setIpDetails] = React.useState(null);
    const [honeyPotValue, setHoneyPotValue] = useState('');
    const [EmailValue, setEmailValue] = useState('');
    const [selectDropdownValue, setSelectDropdownValue] = useState<any>([]);
    const formConfirmationMessage = form_confirmation_message
        ? form_confirmation_message
        : 'Form details have been successfully submitted';

    let doc = typeof document !== 'undefined' ? document : null;
    const cookie: any = doc
        ? doc.cookie.split('; ').reduce((prev: any, current: any) => {
              const [name, ...value] = current.split('=');
              prev[name] = value.join('=');
              return prev;
          }, {})
        : '';
    const { GATSBY_API_URL } = process.env;
    const windowLocationSearch =
        typeof window! == 'undefined' ? '' : window.location.search;
    const urlParams = new URLSearchParams(windowLocationSearch);
    const clientIDParam = urlParams.get('gclid');
    const clientIDValue = clientIDParam ? clientIDParam : '';
    const originOfLeadValue = 'Email Subscription';
    const emailSubscriptionFormValue = 'T';
    const countryValue =
        typeof window == 'undefined'
            ? 'US'
            : window.location.href
                  .split('/')
                  .findIndex((ind) => ind === 'gb-en') !== -1
            ? 'GB'
            : 'US';
    const pageTitleValue = 'Blog subscription';
    let ireferrer = cookie['_uc_referrer'];
    let lreferrer = cookie['_uc_last_referrer'];
    let ilandpage = cookie['_uc_initial_landing_page'];
    let visits = cookie['_uc_visits'];
    let ucontent = cookie['_uc_utm_content'];
    let uSource = cookie['_uc_utm_source'];
    let uMedium = cookie['_uc_utm_medium'];
    let uCampaign = cookie['_uc_utm_campaign'];
    let marketingChannel: any = '';
    let leadSource: any = '';
    let IReferrer = '';
    let referrer = '';
    let referrers = [
        'google',
        'facebook',
        'youtube',
        'bing',
        'yahoo',
        'duckduckgo',
    ];

    if (uSource != '' && uSource !== undefined) {
        if (
            (typeof uMedium === 'string' || uMedium instanceof String) &&
            uMedium.toLowerCase() == 'partner'
        ) {
            let temp = uSource;
            uSource = uMedium;
            uMedium = temp;
        }
        marketingChannel = uSource;
        leadSource = uMedium;
    } else if (ireferrer != '' && ireferrer !== undefined) {
        IReferrer = ireferrer;
        referrer = ireferrer;
        referrers.forEach((ref) => {
            if (ref.includes(ireferrer)) {
                referrer = ref;
                return false; //returning false in foreach is == break
            }
        });
        marketingChannel = referrer;

        if (IReferrer == 'direct') {
            leadSource = 'Direct';
        } else {
            leadSource = 'organic';
        }
    }
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const notify = (msg: string) =>
        toast.dark(msg, {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    marketingChannel =
        marketingChannel !== undefined
            ? marketingChannel.substring(0, 40)
            : marketingChannel;
    ireferrer =
        ireferrer !== undefined ? ireferrer.substring(0, 255) : ireferrer;
    lreferrer =
        lreferrer !== undefined ? lreferrer.substring(0, 255) : lreferrer;
    ilandpage =
        ilandpage !== undefined ? ilandpage.substring(0, 255) : ilandpage;
    leadSource =
        leadSource !== undefined ? leadSource.substring(0, 37) : leadSource;

    let swcookie = Cookies?.get('sw');

    var getPathFromUrl = (stringUrl: string) => {
        return stringUrl.split(/[?#]/)[0];
    };
    const windowLocationHref =
        typeof window! == 'undefined' ? '' : window.location.href;

    const swpage = getPathFromUrl(windowLocationHref);

    const data: any = {
        formValue: {
            fields: [
                {
                    fieldName: 'ipAddress',
                    fieldValue: ipDetails,
                    isHidden: 'true',
                    fieldLabel: ipDetails,
                    isRequired: 'false',
                },
                {
                    fieldName: 'country',
                    fieldValue: countryValue,
                    isHidden: 'true',
                    fieldLabel: 'country',
                    isRequired: 'false',
                },
                {
                    fieldName: 'pageTitle',
                    fieldValue: pageTitleValue,
                    isHidden: 'true',
                    fieldLabel: 'pageTitle',
                    isRequired: 'true',
                },
                {
                    fieldName: 'date',
                    fieldValue: today,
                    isHidden: 'true',
                    fieldLabel: 'date',
                    isRequired: 'true',
                },
                {
                    fieldName: 'iReferrer',
                    fieldValue: ireferrer,
                    isHidden: 'true',
                    fieldLabel: 'iReferrer',
                    isRequired: 'true',
                },
                {
                    fieldName: 'lReferrer',
                    fieldValue: lreferrer,
                    isHidden: 'true',
                    fieldLabel: 'lReferrer',
                    isRequired: 'true',
                },
                {
                    fieldName: 'iLandPage',
                    fieldValue: ilandpage,
                    isHidden: 'true',
                    fieldLabel: 'iLandPage',
                    isRequired: 'true',
                },
                {
                    fieldName: 'visits',
                    fieldValue: visits,
                    isHidden: 'true',
                    fieldLabel: 'visits',
                    isRequired: 'true',
                },
                {
                    fieldName: 'usource',
                    fieldValue: uSource,
                    isHidden: 'true',
                    fieldLabel: 'usource',
                    isRequired: 'true',
                },
                {
                    fieldName: 'umedium',
                    fieldValue: uMedium,
                    isHidden: 'true',
                    fieldLabel: 'umedium',
                    isRequired: 'true',
                },
                {
                    fieldName: 'ucampaign',
                    fieldValue: uCampaign,
                    isHidden: 'true',
                    fieldLabel: 'ucampaign',
                    isRequired: 'true',
                },
                {
                    fieldName: 'ucontent',
                    fieldValue: ucontent,
                    isHidden: 'true',
                    fieldLabel: 'ucontent',
                    isRequired: 'true',
                },
                {
                    fieldName: 'marketingChannel',
                    fieldValue: marketingChannel,
                    isHidden: 'true',
                    fieldLabel: 'marketingChannel',
                    isRequired: 'true',
                },
                {
                    fieldName: 'leadSource',
                    fieldValue: leadSource,
                    isHidden: 'true',
                    fieldLabel: 'leadSource',
                    isRequired: 'true',
                },
                {
                    fieldName: 'emailSubscriptionForm',
                    fieldValue: emailSubscriptionFormValue,
                    isHidden: 'true',
                    fieldLabel: 'emailSubscriptionForm',
                    isRequired: 'true',
                },
                {
                    fieldName: 'cid',
                    fieldValue: clientIDValue,
                    isHidden: 'true',
                    fieldLabel: 'cid',
                    isRequired: 'true',
                },
                {
                    fieldName: 'originOfLead',
                    fieldValue: originOfLeadValue,
                    isHidden: 'true',
                    fieldLabel: 'originOfLead',
                    isRequired: 'true',
                },
                {
                    fieldName: 'referrer',
                    fieldValue: referrer,
                    isHidden: 'true',
                    fieldLabel: 'referrer',
                    isRequired: 'true',
                },
                {
                    fieldName: 'name',
                    fieldValue: NameValue,
                    isHidden: 'false',
                    fieldLabel: nameLabel,
                    isRequired: 'true',
                },
                {
                    fieldName: 'email',
                    fieldValue: EmailValue,
                    isHidden: 'false',
                    fieldLabel: emailAddressLabel,
                    isRequired: 'true',
                },
                {
                    fieldName: 'topics',
                    fieldValue: selectDropdownValue,
                    isHidden: 'false',
                    fieldLabel: interestedFieldLabel,
                    isRequired: 'true',
                },
                {
                    fieldName: 'swcookie',
                    fieldValue: swcookie,
                    isHidden: 'true',
                    fieldLabel: 'swcookie',
                    isRequired: 'true',
                },
                {
                    fieldName: 'swpage',
                    fieldValue: swpage,
                    isHidden: 'true',
                    fieldLabel: 'swpage',
                    isRequired: 'true',
                },
            ],
            formName: headline,
        },
    };

    const formSubmit = async (e: any) => {
        e.preventDefault();
        if (honeyPotValue == '') {
            const salesforceResp = await sendDataToSalesForce();
            const BackUpResp = await getHiddenFieldsValue();
        }
    };

    const sendDataToSalesForce = async () => {
        try {
            let fieldTypes = {};
            data.formValue.fields.map(
                (item: DataFields) =>
                    (fieldTypes[item.fieldName] =
                        item.fieldValue == undefined ? '' : item.fieldValue)
            );
            let dataObj = {
                formName: 'blog subscription form',
                payload: {
                    version: '0.01',
                    ...fieldTypes,
                },
            };
            let zapierHookURL =
                'https://hooks.zapier.com/hooks/catch/3762259/b392nfj';
            if (countryValue == 'GB') {
                zapierHookURL =
                    'https://hooks.zapier.com/hooks/catch/3762259/b66l9l1';
            }
            let response = await fetch(zapierHookURL, {
                method: 'POST',
                headers: {},
                body: JSON.stringify({ form: dataObj }),
            });
            let json_data = await response.json();
            if (json_data.status == 'success') {
                notify(formConfirmationMessage);
            } else {
                notify("Couldn't submit the form");
            }
        } catch (error: any) {
            console.error('error', error.message);
            notify('Error occured while submitting the form');
        }
    };
    const getHiddenFieldsValue = async () => {
        try {
            const request = new Request(
                `${GATSBY_API_URL}/customForm/addFeedBack`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                }
            );
            let resp = await fetch(request);
            let body = await resp.json();
            if (body.result == true) {
                setSelectDropdownValue('');
                setNameValue('');
                setEmailValue('');
            } else {
                notify(body.value);
            }

            return body;
        } catch (err) {
            notify('Error occured while submitting the form');
        }
    };

    const traceIpLocation = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${window.location.origin}/api`)
                .then((response) => {
                    resolve(response.json());
                })
                .catch((err) => {
                    reject({ err });
                });
        });
    };

    const GetInit = async () => {
        try {
            let responseIp = await traceIpLocation();
            setIpDetails(responseIp.ip);
        } catch (err) {
            console.error('unable to fetch ip address');
        }
    };

    React.useEffect(() => {
        GetInit();
    }, []);

    const handleChange = (selectedOption: any) => {
        let obj = {};
        selectedOption.map((item: { label: string; value: string }) => {
            obj[item.label] = item.label;
        });
        setSelectDropdownValue(obj);
    };

    return (
        <>
            <div className={styles.blogForm}>
                <h3 className={styles.formHeader}>{headline}</h3>
                <form onSubmit={formSubmit}>
                    <input
                        id="website"
                        name="website"
                        type="text"
                        className={styles.honeyPotInputField}
                        value={honeyPotValue}
                        onChange={(e) => setHoneyPotValue(e.target.value)}
                    />

                    <div className={styles.col}>
                        <label htmlFor="name"> {nameLabel}</label>
                        <input
                            type="text"
                            {...register('name', { required: true })}
                            id="name"
                            placeholder={nameLabel}
                            onChange={(e) => setNameValue(e.target.value)}
                            value={NameValue}
                            maxLength={40}
                            required
                        ></input>
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            {...register('email', { required: true })}
                            placeholder={emailAddressLabel}
                            required
                            onChange={(e) => setEmailValue(e.target.value)}
                            value={EmailValue}
                            maxLength={80}
                        ></input>
                    </div>
                    <div className={styles.col}>
                        <Select
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    minHeight: '44px',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '0px',
                                    color: 'black',
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    width: '0px',
                                    margin: '0px',
                                }),
                                option: (styles, { isSelected }) => ({
                                    ...styles,
                                    color: isSelected ? 'red' : 'black',
                                    background: isSelected
                                        ? 'lightgrey'
                                        : 'white',
                                }),
                                dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                        ? 'rotate(-180deg)'
                                        : 'rotate(0)',
                                    transition: '250ms',
                                }),
                                valueContainer: (styles) => ({
                                    ...styles,
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                }),
                            }}
                            className={styles.defaultOption}
                            id="topics"
                            placeholder={interestedFieldLabel}
                            {...register('topics', { required: false })}
                            isMulti
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            backspaceRemovesValue={false}
                            components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable={false}
                            isClearable={false}
                            options={blogTopics.map((topic, index) => ({
                                value: index,
                                label: topic.name,
                            }))}
                            defaultValue={selectDropdownValue}
                            onChange={handleChange}
                        />
                        {interest_hint_text ? (
                            <h6 className={styles.hintText}>
                                {interest_hint_text}
                            </h6>
                        ) : null}
                    </div>
                    <div className={styles.col}>
                        <button type="submit">{cta}</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </>
    );
};

export default BlogSubscriptionForm;
