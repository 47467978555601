import {
    CTALocation,
    KontentStringValue,
} from '../../../../types/KontentProps';

export function BuildLinkHref(
    cta: CTALocation | undefined,
    anchorPoint: KontentStringValue | undefined = undefined
) {
    if (!cta?.value || !cta?.value.length) {
        return '';
    }
    const addAnchor = (url: string) => {
        let anchor = anchorPoint?.value && `#${anchorPoint?.value}`;

        if (anchor) {
            return url + '/' + anchor;
        }

        return url;
    };

    if (cta?.value[0]?.elements?.third_party_url?.value) {
        return addAnchor(cta?.value[0]?.elements?.third_party_url?.value);
    }
    if (cta?.value[0]?.elements?.file?.value[0]?.url) {
        return addAnchor(cta?.value[0]?.elements?.file?.value[0]?.url);
    }
    if (cta?.value[0]?.elements?.document?.value[0]?.url) {
        return addAnchor(cta?.value[0]?.elements?.document?.value[0]?.url);
    }

    if (
        !cta?.value[0]?.elements?.slug?.url &&
        cta?.value[0]?.elements?.slug?.value
    ) {
        return addAnchor(`/${cta?.value[0]?.elements?.slug?.value}`);
    }

    return addAnchor(`${cta?.value[0]?.elements?.slug?.url}`);
}
