// extracted by mini-css-extract-plugin
export var categoryHeadline = "footer-module--categoryHeadline--0d01c";
export var connectForm = "footer-module--connectForm--937f4";
export var copyright = "footer-module--copyright--d4641";
export var coutryStyle = "footer-module--coutryStyle--ddcd5";
export var icon = "footer-module--icon--07b48";
export var legalreq = "footer-module--legalreq--d45e4";
export var linkButtonContainer = "footer-module--linkButtonContainer--a8408";
export var linkIcon = "footer-module--linkIcon--f36f6";
export var mobileApps = "footer-module--mobileApps--a3b51";
export var mobileAppsIcons = "footer-module--mobileAppsIcons--4b351";
export var mobileStoreBtn = "footer-module--mobileStoreBtn--43de0";
export var modifiedreq = "footer-module--modifiedreq--36c69";
export var navContent = "footer-module--navContent--e8a77";
export var navSection = "footer-module--navSection--92041";
export var richText = "footer-module--richText--48cae";
export var socialIcons = "footer-module--socialIcons--d395d";
export var title = "footer-module--title--13230";
export var wrapper = "footer-module--wrapper--fa68c";